<template>
  <select
    v-if="showPortfolioSelector"
    v-model="selectedPortfolio"
    @change="setPortfolio($event)"
    class="portfolioSelect form-control">
    <b-form-select-option :value="''">
      Select a Portfolio
    </b-form-select-option>
    <template v-for="(clientPortfolios, clientId) in portfolioList">
      <option :value="clientId" type="client" class="client">
        {{ clientId }}
      </option>
      <option
        v-for="portfolio in clientPortfolios"
        :key="portfolio.portfolio_id"
        :value="portfolio.portfolio_id"
        type="portfolio"
        class="portfolio">
        &nbsp;&nbsp;&nbsp;&nbsp; {{ portfolio.portfolio_id }}
      </option>
    </template>
  </select>
</template>

<script>
import {
  getPortfolioDetail,
  getClientDetail,
  getPortfolioPerformance,
  getClientPerformance,
} from "../services/api.js";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "PortfolioSelector",
  data() {
    return {};
  },
  methods: {
    setPortfolio(event) {
      var self = this;
      // Pongo en cero los valores del cuadro de YTDPerformance hasta que se traigan
      // los valores correctos del store. A futuro, esta funcion debe ir en el componente YTDPerformance
      // junto con getPortfolioPerformance().
      this.$store.commit("SET_PORTFOLIOPERFORMANCE", {});
      this.$store.commit("SET_PORTFOLIODATA", []);

      // Si fue por selección, guardo tipo de Portfolio en el store (el value ya está bindeado)
      if (event !== undefined) {
        this.selectedPortfolioType = event.target.options[event.target.options.selectedIndex].getAttribute("type");
        // Esto de aca es un hack temporal para evitar que cuando cambio el Portfolio, me lleve a la portfolio view.
        const urlParams = this.$route.path.split("/");
        const portfolioTypeParam = urlParams[1];
        const portfolioParam = decodeURIComponent(urlParams[2]);
        if (
          portfolioParam &&
          (portfolioTypeParam === "client" || portfolioTypeParam === "portfolio")
        ) {
          if (this.selectedPortfolio && this.selectedPortfolioType) {
            this.$router.replace({ path: `/${this.selectedPortfolioType}/${this.selectedPortfolio}/statement` });
          } else {
            this.$router.replace({ path: `/portfolios/statement` });
          }
        }
      }

      if (this.selectedPortfolio) {
        if (this.selectedPortfolioType === "client") {
          getClientDetail(this.selectedPortfolio).then(function (response) {
            self.storePortfolioData(response);
          });
          getClientPerformance(this.selectedPortfolio).then(function (
            response
          ) {
            self.storePerformanceData(response);
          });
        } else if (this.selectedPortfolioType === "portfolio") {
          getPortfolioDetail(this.selectedPortfolio).then(function (response) {
            self.storePortfolioData(response);
          });
          getPortfolioPerformance(this.selectedPortfolio).then(function (
            response
          ) {
            self.storePerformanceData(response);
          });
        }
      }
    },
    storePerformanceData(response) {
      let payload = response.data.data;
      let performanceData = {
        BalanceDate: payload?.balance_date ?? null,
        InitialNetWorth: payload?.balance ?? null,
        TransfersInOut: payload?.transfers ?? null,
        CurrentNetWorth: payload?.current_portfolio ?? null,
        ProfitAndLossInCapital: payload?.pnl ?? null,
        TimeWeightedReturn: payload?.hpr ?? null,
        MoneyWeightedReturn: payload?.mwr ?? null,
        AnnualizedReturn: payload?.ar ?? null,
      };
      this.$store.commit("SET_PORTFOLIOPERFORMANCE", performanceData);
    },
    storePortfolioData(response) {
      let payload = response.data.data;
      if (payload === undefined || payload === "") {
        this.selectedPortfolio = "";
        this.selectedPortfolioType = "";
      }
      if (payload && payload.length > 0) {
        payload.map((element) => (element.uuid = uuidv4()));

        this.$store.commit("SET_PORTFOLIODATA", payload);
      }
    },

    isValidPortfolio() {
      let validPortfolio;

      if (this.selectedPortfolioType === "client") {
        validPortfolio = this.portfolioList.some((item) => {
          return item.client_id === this.selectedPortfolio;
        });
      } else if (this.selectedPortfolioType === "portfolio") {
        validPortfolio = this.portfolioList.some((item) => {
          return item.portfolio_id === this.selectedPortfolio;
        });
      }
      return validPortfolio;
    },
  },
  watch: {
    portfolioList: {
      handler() {
        /*
         * Valido que exista el portfolio cuando se carga la lista de portfolios.
         */
        if (this.selectedPortfolio && this.selectedPortfolioType) {
          if (!this.isValidPortfolio()) {
            this.selectedPortfolio = "";
            this.selectedPortfolioType = "";
          }
        }
      },
    },
    $route(to) {
      if (
        to.params.portfolioType === "client" ||
        to.params.portfolioType === "portfolio"
      ) {
        if (this.selectedPortfolio !== to.params.portfolioName) {
          this.selectedPortfolio = to.params.portfolioName;
          this.selectedPortfolioType = to.params.portfolioType;
          this.setPortfolio();
        }
      }
    },

    portfolioData() {
      if (this.selectedPortfolio === "") {
        if (this.portfolioData.length === 0) {
          this.selectedPortfolio = "";
          this.selectedPortfolioType = "";
        }
      }
    },
  },

  computed: {
    portfolioData() {
      return this.$store.state.PortfolioData;
    },
    showPortfolioSelector() {
      return (
        this.$route.path.startsWith("/portfolio/") ||
        this.$route.path.startsWith("/client/") ||
        this.$route.path.startsWith("/portfolios/")
      );
    },
    portfolioList: {
      get() {
        return this.$store.state.portfolioList;
      },

      set(value) {
        this.$store.commit("SET_PORTFOLIOLIST", value);
      },
    },

    selectedPortfolio: {
      get() {
        return this.$store.state.selectedPortfolio;
      },
      set(value) {
        this.$store.commit("SET_SELECTEDPORTFOLIO", value);
      },
    },
    selectedPortfolioType: {
      get() {
        return this.$store.state.selectedPortfolioType;
      },
      set(value) {
        this.$store.commit("SET_SELECTEDPORTFOLIO_TYPE", value);
      },
    },
    portfolioList() {
      if (this.$store.state.portfolioList.length) {
        let portfolios = this.$store.state.portfolioList;
        let grouped = {};
        let counter = 0;
        portfolios.forEach((portfolio) => {
          let client = portfolio.client_id;
          if (!grouped[client]) grouped[client] = [];
          grouped[client].push({ ...portfolio, key: `${client}_${counter++}` });
        });
        return grouped;
      };
    }
  },
  mounted() {
    this.$store.dispatch("loadPortfoliosList");

    // Refactorizar este bloque hasta el if y volverlo un metodo
    const urlParams = this.$route.path.split("/");
    const portfolioTypeParam = urlParams[1];
    const portfolioParam = decodeURIComponent(urlParams[2]);

    if (
      portfolioParam &&
      (portfolioTypeParam === "client" || portfolioTypeParam === "portfolio")
    ) {
      // Asignar los parámetros de la URL
      this.selectedPortfolio = portfolioParam;
      this.selectedPortfolioType = portfolioTypeParam;
    }
    this.setPortfolio();
  },
};
</script>

<style>
.portfolioSelect {
  width: 260px;
}
.portfolio {
  padding-left: 20px;
}

.client {
  font-weight: bold;
}
</style>
