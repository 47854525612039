export default [
  {
    _name: "CSidebarNav",
    _children: [
      {
        _name: "CSidebarNavTitle",
        _children: ["Portfolios"],
      },
      {
        _name: "CSidebarNavItem",
        name: "Summary View",
        to: "/portfolios/views/summary",
        icon: "cil-grid",
      },
      {
        _name: "CSidebarNavItem",
        name: "Portfolio View",
        to: "/portfolios/statement",
        icon: "cil-briefcase",
      },
      {
        _name: "CSidebarNavItem",
        name: "New Position",
        to: "/portfolios/views/positions",
        icon: "cil-plus",
      },
      {
        _name: "CSidebarNavItem",
        name: "Transfers",
        to: "/portfolios/account/transfers",
        icon: "cil-transfer",
      },
      {
        _name: "CSidebarNavItem",
        name: "Orders",
        to: "/portfolios/account/orders",
        icon: "cil-graph",
      },
      /*     {
      _name: 'CSidebarNavTitle',
      _children: ['ACCOUNTING']
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Journal',
      to: '/accounting/journal',
      icon: 'cil-drop'
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Ledger',
      to: '/accounting/ledger',
      icon: 'cil-drop'
    },
    {
      _name: 'CSidebarNavDropdown',
      name: 'Reports',
      route: '/Reports',
      icon: 'cil-puzzle',
      items:[ {
        name: 'Financial Position',
        to: '/accounting/Reports/financialposition'
      },
      {
        name: 'Comprehensive Income',
        to: '/accounting/Reports/comprehensiveincome'
      },
      {
        name: 'Cash Flow',
        to: '/accounting/Reports/cashflow'
      }]
    },
    {
      _name: 'CSidebarNavDropdown',
      name: 'Accounts',
      route: '/accounts',
      icon: 'cil-puzzle',
      items:[ {
        name: 'View',
        to: '/accounting/accounts/view'
      },
      {
        name: 'Add',
        to: '/accounting/accounts/add'
      }
      ]
    },
    {
      _name: 'CSidebarNavTitle',
      _children: ['DOCUMENTS']
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Login',
      to: '/documents/login',
      icon: 'cil-drop'
    },
    {
      _name: 'CSidebarNavTitle',
      _children: ['STP MESSAGES']
    },
    {
      _name: 'CSidebarNavDropdown',
      name: 'View',
      route: '/view',
      icon: 'cil-puzzle',
      items:[ {
        name: 'Pending',
        to: '/stp/view/pending'
      },
      {
        name: 'Imported',
        to: '/stp/view/imported'
      },
      {
        name: 'All',
        to: '/stp/view/all'
      }
      ]
    },
    {
      _name: 'CSidebarNavItem',
      name: 'Import',
      to: '/stp/import',
      icon: 'cil-drop'
    },
    {
      _name: 'CSidebarNavDropdown',
      name: 'Positions',
      route: '/positions',
      icon: 'cil-puzzle',
      items:[ {
        name: 'Pending',
        to: '/stp/positions/pending'
      },
      {
        name: 'Archived',
        to: '/stp/positions/archived'
      }

      ]
    },
    {
      _name: 'CSidebarNavDropdown',
      name: 'Operations',
      route: '/operations',
      icon: 'cil-puzzle',
      items:[ {
        name: 'Pending',
        to: '/stp/operations/pending'
      },
      {
        name: 'Archived',
        to: '/stp/operations/archived'
      }

      ]
    } */
    ],
  },
];
