<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div>
          <b-avatar class="mr-3"></b-avatar>
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>Settings</strong>
    </CDropdownHeader>
    <CDropdownItem
      :href="`${KEYCLOAK_AUTH_URL}/realms/${KEYCLOAK_REALM}/account/`"
      ><CIcon name="cil-user" /> Profile
    </CDropdownItem>
    <CDropdownDivider />
    <CDropdownItem
      :href="`${KEYCLOAK_AUTH_URL}/realms/${KEYCLOAK_REALM}/protocol/openid-connect/logout?redirect_uri=${KEYCLOAK_REDIRECT_URI}`"
      ><CIcon name="cil-lock-locked" /> Logout
    </CDropdownItem>
  </CDropdown>
</template>

<script>
export default {
  name: "TheHeaderDropdownAccnt",
  data() {
    return {
      KEYCLOAK_AUTH_URL: process.env.VUE_APP_KEYCLOAK_AUTH_URL,
      KEYCLOAK_REALM: process.env.VUE_APP_KEYCLOAK_REALM,
      KEYCLOAK_REDIRECT_URI: process.env.VUE_APP_KEYCLOAK_REDIRECT_URI,
    };
  },
};
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
</style>
