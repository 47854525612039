<template>
  <CHeader fixed with-subheader light>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand class="mx-auto d-lg-none" to="/">
     <img src="@/assets/img/se_group_ltd.png" height="45" class="c-sidebar-brand-full"/>
 
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
 
    </CHeaderNav>
    <CHeaderNav class="mr-4">
  
      <TheHeaderDropdownAccnt/>
    </CHeaderNav>
    <CSubheader class="px-3 pb-3 pb-md-0 align-items-center justify-content-center justify-content-md-between">
      <CBreadcrumbRouter class="border-0 mb-0" style="background-color: rgb(255 255 255);"/>
      <PortfolioSelector />
    </CSubheader>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'
import PortfolioSelector from '@/functionalComponents/portfolioSelector.vue'
export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt,
    PortfolioSelector
  }
}
</script>
